import s from './index.module.scss';

import { navigate } from 'gatsby';
import React, { Fragment } from 'react';
import coronaLogo from '../../../static/icons/logo.png';

//Components
import Button from '../../components/button/index';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Seo from '../../components/Seo';

const Home = () => {
  return (
    <Fragment>
      <Seo
        lang="es"
        title="Inicio"
        description="Bienvenido a coronasunsetchile.com"
      />

      <Header />
      <div className={s.container}>
        <div className={s.content}>
          <img className={s.logo} alt="coronaLogo" src={coronaLogo} />

          <div>
            <p className={s.presenta}>presenta,</p>
            <p className={s.coronaSessions}>corona sessions</p>
          </div>
          <p className={s.text}>
            Los mejores planes son los que no se planean tanto. Motiva a 15
            amigos a vivir un Corona Festival todo incluido en la playa o la
            montaña y nosotros hacemos el resto.
          </p>

          <Button
            type="submit"
            value="Crea tu Session"
            className="homeButton"
            onClick={() => navigate('/experiences')}
          />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Home;
